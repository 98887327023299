import { io } from 'socket.io-client';

import { bus } from '@/js/plugins/bus';

import msalInstance from '@/js/auth/msal';

import {
	WEBSERVER_URL,
	ACTIVITY_NAMESPACE,
	CONNECT_SOCKETS,
	EMIT_ACTIVITY_IS_ACTIVE
} from '@/env';

const activitySocket = io(WEBSERVER_URL + ACTIVITY_NAMESPACE, {
	autoConnect: false,
	withCredentials: true,
	transports: [ "websocket" ],
	auth: async callback => callback({ token: await msalInstance.idToken() })
});

bus.on(CONNECT_SOCKETS, async () => {
	const isAuthorized = !!await msalInstance.idToken();
	if (!activitySocket.connected && isAuthorized) {
		activitySocket.connect();
	}
});

const isActive = () => {
	if (activitySocket.connected) {
		activitySocket.emit(EMIT_ACTIVITY_IS_ACTIVE);
	}
};

export { activitySocket, isActive };
